export const MutationAgencyProfileUpdate = `
  mutation (
    $id: Int!
    $fullName: String
    $phoneNumber: [String!]
    $email: [String!]
    $resumeFile: File
    $avatar: File
    $summary: String
    $headline: String
    $location: String
    $skills: [String!]
    $sourced: ProfileSourced
    $typeOfExpectedSalary: ProfileTypeOfExpectedSalary
    $typeOfCurrentSalary: ProfileTypeOfCurrentSalary
    $expectedSalary: Int
    $expectedSalaryCurrency: String
    $currentSalary: Int
    $currentSalaryCurrency: String
    $willingToRelocate: Boolean
    $openToWork: Boolean
    $noticeToPeriodDays: String
    $nationality: String
    $ownerId: Int
    $links: JSON
    $languages: [JSON!]
    $references: [JSON!]
    $certificates: [JSON!]
    $workExperiences: [JSON!]
    $educations: [JSON!]
    $applicants: [JSON!]
    $birthday: JSON
    $sourcedName: String
    $totalYearsOfExp: Int
    $profileTalentPoolIds: [Int!]
    $customFields: [JSON!]
    $profileLevel: ProfileProfileLevel
    $countryStateId: Int
    $addActionLogsRelatedToAi: JSON
  ) {
    profilesUpdate(
      input: {
        id: $id
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        resumeFile: $resumeFile
        avatar: $avatar
        summary: $summary
        headline: $headline
        location: $location
        skills: $skills
        sourced: $sourced
        typeOfExpectedSalary: $typeOfExpectedSalary
        typeOfCurrentSalary: $typeOfCurrentSalary
        expectedSalary: $expectedSalary
        expectedSalaryCurrency: $expectedSalaryCurrency
        currentSalary: $currentSalary
        currentSalaryCurrency: $currentSalaryCurrency
        willingToRelocate: $willingToRelocate
        openToWork: $openToWork
        noticeToPeriodDays: $noticeToPeriodDays
        nationality: $nationality
        ownerId: $ownerId
        links: $links
        languages: $languages
        references: $references
        certificates: $certificates
        workExperiences: $workExperiences
        educations: $educations
        applicants: $applicants
        birthday: $birthday
        sourcedName: $sourcedName
        totalYearsOfExp: $totalYearsOfExp
        profileTalentPoolIds: $profileTalentPoolIds
        customFields: $customFields
        profileLevel: $profileLevel
        countryStateId: $countryStateId
        addActionLogsRelatedToAi: $addActionLogsRelatedToAi
      }
    ) {
      profile {
        id
        fullName
        email
        headline
        phoneNumber
        address
        avatarVariants
        coverLetter
        countryStateId
        links
        sourced
        sourcedDescription
        sourcedName
        sourcedNameDescription
        createdAt
        profileCvs {
          id
          attachments {
            id
            file
            blobs
          }
        }
        jobs {
          id
          title
        }
        applicants {
          id
          coverLetter
          job {
            status
            id
            title
            slug
            owner {
              id
              fullName
              email
            }
          }
        }
        user {
          id
          fullName
          avatarVariants
          defaultColour
        }
        owner {
          id
          fullName
          avatarVariants
          defaultColour
        }
        totalYearsOfExp
        permittedFields
        tags {
          value
          name
          id
        }
        warning
        defaultAccessibleApplicantId
        customFields
      }
    }
  }
`

export default MutationAgencyProfileUpdate
